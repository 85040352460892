import { defineStore } from 'pinia';

export const useResidentStore = defineStore(
  'residentStore',
  () => {
    const timezone = ref();
    const sidebarVisible = ref(false);
    const contractAddress = ref();
    const allowedActiveGuestVehicles = ref();
    const allowedResidentVehicles = ref();
    const apartmentUnitId = ref();
    const city = ref();
    const garageCovered = ref();
    const guestHours = ref();
    const guestPeriod = ref();
    const guestTimes = ref();
    const residentId = ref();
    const contractName = ref();
    const contractPhone = ref();
    const reservedParking = ref();
    const residentEmail = ref();
    const residentName = ref();
    const residentPhone = ref();
    const state = ref();
    const unitNumber = ref();
    const contractZipcode = ref();
    const residentVehicles = ref();
    const residentPincode = ref();
    const guestPincode = ref();
    const contractId = ref();
    const hasGuestRegistration = ref();
    const contractImage = ref();
    const activeGuestVehicles = ref();
    const isPaidParking = ref();

    const setResidentData = (data: any) => {
      contractId.value = data.contract_id;
      contractAddress.value = data.address;
      allowedActiveGuestVehicles.value = data.allowed_active_guest_vehicles;
      allowedResidentVehicles.value = data.allowed_resident_vehicles;
      apartmentUnitId.value = data.apartment_unit_id;
      city.value = data.city;
      garageCovered.value = data.garage_covered;
      guestHours.value = data.guest_hours;
      guestPeriod.value = data.guest_period;
      residentId.value = data.id;
      contractName.value = data.name;
      contractPhone.value = data.phone;
      reservedParking.value = data.reserved_parking;
      residentEmail.value = data.resident_email;
      residentName.value = data.resident_name;
      residentPhone.value = data.resident_phone;
      state.value = data.state;
      unitNumber.value = data.unit_number;
      contractZipcode.value = data.zip_code;
      residentPincode.value = data.resident_pin_code;
      guestPincode.value = data.guest_pin_code;
      hasGuestRegistration.value = data.has_guest_registration;
      contractImage.value = data.file_url;
      isPaidParking.value = data.has_paid_parking;

      if (data.resident_vehicles) {
        residentVehicles.value = data.resident_vehicles;
      } else {
        residentVehicles.value = [];
      }
    };

    const clearStore = () => {
      setResidentData({});
    };

    return {
      contractId,
      contractAddress,
      allowedActiveGuestVehicles,
      allowedResidentVehicles,
      apartmentUnitId,
      city,
      garageCovered,
      guestHours,
      guestPeriod,
      guestTimes,
      residentId,
      contractName,
      contractPhone,
      reservedParking,
      residentEmail,
      residentName,
      residentPhone,
      state,
      unitNumber,
      contractZipcode,
      residentVehicles,
      setResidentData,
      residentPincode,
      guestPincode,
      clearStore,
      sidebarVisible,
      timezone,
      hasGuestRegistration,
      contractImage,
      activeGuestVehicles,
      isPaidParking,
    };
  },
  { persist: { storage: window.localStorage } }
);
